<style type="text/css">
@import "~@/assets/css/common.css";
.msgVal {
  padding-right: 10px;
}
</style>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import grade from "@/components/grade/grade";

// import $ from "jquery"
import {
  getgrade,
  examReviewApply,
  getTemplateInfo,
  getLibraryInfo,
} from "@/api/grade";
import { getExamInfo, getUserExamOne } from "@/api/examination";
import { format } from "@/libs/common";

/**
 * 报名信息提交
 */
export default {
  components: {
    Layout,
    PageHeader,
    grade,
  },
  data() {
    return {
      title: "成绩复核申请提交",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "成绩复核",
          active: true,
        },
      ],
      query: "",
      examInfo: {},
      scoreData: {},
      gradeData: [
        "cj",
        "cjcllx",
        "dah",
        "jmcj",
        "km",
        "kmmc",
        "kmtg",
        "rdrq",
        "wjlbm",
        "wjrdiy",
        "ycl",
        "zkzh",
      ],
      gradeDataList: [],
      recheckList: [],
      yycs: "",
      library: {},
      imgUrl: "",
      list1: [],
      sfxszp: false,
      fhgz: [],
    };
  },
  methods: {
    recheck() {
      let isSubmit = true;
      let num = 0;
      let obj = {
        ksbmbh: this.examInfo.ksbmbh,
        cjkid: this.query.cjkid,
        yycs: this.yycs,
        examReviewBeanList: [],
      };
      this.recheckList.forEach((v, i) => {
        // if (v.cjxhList.length && !v.ygcj) {
        //   this.$message("请输入" + this.gradeDataList[i].kmmc + "的预估成绩");
        //   isSubmit = false;
        //   num += 1;
        //   return false;
        // } else
        if (v.cjxhList.length) {
          obj.examReviewBeanList.push(v);
          num += 1;
        }
      });
      if (num == 0) {
        this.$message({
          message: "请选择申请复核的成绩",
          type: "warning",
        });
        return false;
      }
      // if (!this.yycs) {
      //   this.$message("请输入申请理由");
      //   return false;
      // }

      if (isSubmit) {
        obj.examReviewBeanList.forEach((v) => {
          v.cjxh = v.cjxhList[0];
        });
        examReviewApply(obj).then((res) => {
          if (res.status) {
            this.$message({
              message: "申请成功",
              type: "success",
            });
            this.$router.push({ path: "./recordReview" });
          }
        });
      }
    },
    dealData(data, index) {
      let obj = {};
      let cjData = data["cj" + index];
      if (this.library.kskms >= index) {
        this.gradeData.map((key) => {
          obj[key] = data[key + index];
        });
        obj["cjxh"] = index;
        let nffh = false;
        let gz = this.fhgz[index - 1].split("_");
        for (let index = 0; index < gz.length; index++) {
          if (gz[index] == "N") {
            nffh = true;
            break;
          }
          if (gz[index].includes("eq")) {
            if (cjData == gz[index].slice(2)) {
              nffh = true;
              break;
            }
          }
          if (gz[index].includes("le")) {
            if (cjData <= gz[index].slice(2)) {
              nffh = true;
              break;
            }
          }
          if (gz[index].includes("lt")) {
            if (cjData < gz[index].slice(2)) {
              nffh = true;
              break;
            }
          }
          if (gz[index].includes("ge")) {
            if (cjData >= gz[index].slice(2)) {
              nffh = true;
              break;
            }
          }
          if (gz[index].includes("gt")) {
            if (cjData > gz[index].slice(2)) {
              nffh = true;
              break;
            }
          }
        }

        if (nffh && cjData !== null) {
          this.gradeDataList.push(obj);
        }
        this.dealData(data, index + 1);
      } else {
        this.gradeDataList.forEach((v) => {
          this.recheckList.push({
            cjxhList: [],
            ygcj: "",
          });
        });
      }
    },
    getLibrary() {
      getLibraryInfo(this.query.cjkid).then((res) => {
        if (res.status) {
          this.fhgz = res.data.fhcjfw.split(",");
          res.data.fhkssj = format(res.data.fhkssj, "yyyy-MM-dd");
          res.data.fhjssj = format(res.data.fhjssj, "yyyy-MM-dd");
          if (
            new Date(res.data.fhkssj) < new Date() &&
            new Date(res.data.fhjssj) > new Date() &&
            res.data.sfyxfh == 1
          ) {
            res.data.canfh = true;
          }
          this.library = res.data;
          this.getgradeTemplate(res.data.mbh);
          this.getGrade();
        }
      });
    },
    getGrade() {
      getgrade({
        cjkid: this.query.cjkid,
      }).then((res) => {
        if (res.status) {
          if (res.data) {
            this.dealData(res.data, 1);
            this.scoreData = res.data;
          }
        }
      });
    },
    getgradeTemplate(mbh) {
      getTemplateInfo(mbh).then((res) => {
        if (res.status) {
          this.imgUrl = this.getFileUrl(res.data.cjbjwj);
          this.list1 = res.data.printTemplateFieldBeanList;
          this.sfxszp = res.data.sfxszp;
        }
      });
    },
  },
  created() {
    this.query = this.$route.query;
    this.getLibrary();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">
              <img
                src="@/assets/images/person/icon/if-paper.png"
                class="mr-2"
              />请选择需要复核的科目
            </h4>
            <div class="outerCotent mt-3 score-list">
              <div class="msgContent flexList fs-xs" style="border: unset">
                <!-- <grade
                  :list="list1"
                  :grade="scoreData"
                  :library="library"
                  :zpxs="sfxszp"
                ></grade> -->
                <div class="w-100 msgContent">
                  <div class="w-100 check-score">{{gradeDataList.length != 0 ?'请选择准备复核的科目':"暂无可复核科目成绩"}}</div>
                  <div
                    class="w-100 d-flex"
                    v-for="(item, index) in gradeDataList"
                    :key="index"
                  >
                    <div class="w-100 d-flex msgLabel">
                      <div class="msgName flexList justify-content-center">
                        复核科目({{ item.cjxh}})
                      </div>
                      <div class="msgVal flexList">
                        <div class="form-check" style="line-height: 25px">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="item.cjxh"
                            v-model="recheckList[index].cjxhList"
                          />
                          <span class="mr-2">选择</span>
                          <label class="form-check-label" for="defaultCheck1">{{
                            item.kmmc
                          }}</label>
                          <span> ({{ item.cj }}分)</span>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="w-50 d-flex msgLabel">
                      <div class="msgName flexList justify-content-center">
                        预估成绩
                      </div>
                      <div class="msgVal flexList">
                        <b-form-input
                          type="number"
                          name="number"
                          class="msgValInput w-50"
                          v-model="recheckList[index].ygcj"
                        >
                        </b-form-input>
                      </div>
                    </div> -->
                  </div>

                  <!-- <div class="d-flex w-100 msgLabel" style="height: 145px">
                    <div class="msgName flexList justify-content-center">
                      申请理由
                    </div>
                    <div class="msgVal flexList">
                      <textarea
                        type="textarea"
                        rows="3"
                        class="msgValInput form-control"
                        style="width: 100%"
                        placeholder="本人保证所提供及填写的资料真实可靠，否则，愿承担一切责任及后果。"
                        maxlength="500"
                        v-model="yycs"
                      ></textarea>
                    </div>
                  </div> -->
                  <div class="d-flex w-100 msgLabel" style="height: 60px">
                    <div class="msgName flexList justify-content-center">
                      特别提示
                    </div>
                    <div class="msgVal flexList">
                      {{library.fhtsxx?library.fhtsxx:"无"}}
                    </div>
                  </div>
                  <div class="d-flex w-100 justify-content-center " style="height: 60px" v-if="recheckList.length != 0">
                    <div class=" flexList">
                      <button
                        type="button"
                        class="btn btn-info w-md mr-3 h30"
                        @click="recheck"
                      >
                        提交复核申请
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary w-sm h30"
                        @click="$router.back()"
                      >
                        取消
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
